import { State } from '../../../redux/reducers';

export const getMyActivatedTickets = (state: State) => state.tickets.activated;
export const getMyBundles = (state: State) => state.tickets.bundles;
export const getAvailableProducts = (state: State) =>
  state.tickets.availableProducts;
export const getNewTicketData = (state: State) => state.tickets.newTicket;
export const getActivateTicketData = (state: State) =>
  state.tickets.activateTicket;
export const getCannotFetchAvailableProducts = (state: State) =>
  state.tickets.cannotFetchAvailableProducts;
