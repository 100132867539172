import styled from 'styled-components';
import {
  Button,
  Card as DSCard,
  CardImage,
  Flex,
  Paragraph,
} from 'pqbc-vas-design-system';

export const NoTicketContainer = styled(DSCard)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

export const NoTicketsImage = styled(CardImage)`
  width: 100%;
  height: 85px;
`;

export const EmptyViewText = styled(Paragraph)`
  color: ${p => p.theme.colors.neutral600};
  text-align: center;
  margin-top: 30px;
`;

export const ActivatedTickets = styled.div`
  color: ${p => p.theme.colors.neutral100};
  margin: 20px 16px 0px 16px;
`;

export const TicketsContainer = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
`;

export const TicketContainer = styled(DSCard)`
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: white;
  padding: 16px;
  flex-direction: column;
  color: ${p => p.theme.colors.secondary500};
`;

export const BundleContainer = styled(DSCard)`
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
  background-color: white;
  padding: 16px;
  flex-direction: column;
  color: ${p => p.theme.colors.secondary500};
`;

export const TicketFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled(Flex)`
  flex-direction: column;
`;

export const Title = styled(Paragraph)`
  text-transform: lowercase;
  font-size: 18px;
  font-weight: 700;
`;

export const ValidUntil = styled(Paragraph)`
  color: ${p => p.theme.colors.neutral600};
`;

export const Countdown = styled(Flex)`
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  text-transform: lowercase;
`;

export const GetTicketButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
`;
