import styled from 'styled-components';
import { Dialog, Flex, Button as DSButton } from 'pqbc-vas-design-system';

export const DialogMessage = styled(Dialog.Message)`
  white-space: pre-line;
`;

export const ButtonGroup = styled(Flex)`
  /* All children except first */
  & > * + * {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Button = styled(DSButton)`
  width: 100%;
`;

export const Mailto = styled.a`
  text-decoration: none;
  width: 100%;
  color: inherit;
  display: block;
`;
