import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions';
import { Customer } from '../types';

export interface UserState {
  isLatestTermsAndConditionsAccepted: boolean;
  customer: Customer;
}

export const initialState: UserState = {
  isLatestTermsAndConditionsAccepted: true,
  customer: {
    number: '',
  },
};

const userReducer: Reducer<UserState, ActionType<typeof actions>> = (
  state = initialState,
  action,
): UserState => {
  switch (action.type) {
    case getType(actions.fetchTermsAndConditionsConsentStatus.success): {
      return {
        ...state,
        isLatestTermsAndConditionsAccepted:
          action.payload.content.consent_given,
      };
    }
    case getType(actions.acceptLatestTermsAndConditions.success): {
      return {
        ...state,
        isLatestTermsAndConditionsAccepted:
          action.payload.content.consent_given,
      };
    }
    case getType(actions.getCustomerProfile.success): {
      return {
        ...state,
        customer: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
