import { takeEvery, put, call } from 'redux-saga/effects';
import { ducks, HTTPMethod } from 'pqbc-vas-core';
import * as actions from '../actions';
import {
  TermsAndConditionsConsentStatusResponse,
  TermsAndConditionsConsentStatusUpdatedResponse,
} from '../types';

export function* fetchTermsAndConditionsConsentStatusSaga() {
  try {
    const response: TermsAndConditionsConsentStatusResponse = yield call(
      ducks.Api.sagas.callApi,
      '/terms-and-conditions',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.fetchTermsAndConditionsConsentStatus.success(response));
  } catch (error) {
    yield put(actions.fetchTermsAndConditionsConsentStatus.failure(error));
  }
}

export function* acceptLatestTermsAndConditionsSaga() {
  try {
    const response: TermsAndConditionsConsentStatusUpdatedResponse = yield call(
      ducks.Api.sagas.callApi,
      '/terms-and-conditions',
      {
        method: HTTPMethod.PUT,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.acceptLatestTermsAndConditions.success(response));
  } catch (error) {
    yield put(actions.acceptLatestTermsAndConditions.failure(error));
  }
}

export function* getCustomerProfileSaga() {
  try {
    const response = yield call(
      ducks.Api.sagas.callApi,
      '/customer/customers',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.getCustomerProfile.success(response.content[0]));
  } catch (error) {
    yield put(actions.getCustomerProfile.failure(error));
  }
}

export function* UserSaga() {
  yield takeEvery(
    actions.fetchTermsAndConditionsConsentStatus.request,
    fetchTermsAndConditionsConsentStatusSaga,
  );
  yield takeEvery(
    actions.acceptLatestTermsAndConditions.request,
    acceptLatestTermsAndConditionsSaga,
  );
  yield takeEvery(actions.getCustomerProfile.request, getCustomerProfileSaga);
}
