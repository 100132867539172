import {
  AppNavigationProvider,
  Back24Icon,
  Navigation,
  NavigationBarIconButton,
} from 'pqbc-vas-design-system';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RemoveScroll } from 'react-remove-scroll';
import { useTranslation } from 'react-i18next';
import bridge from '../../bridge';
import { Routes } from '../index';
import { ReactComponent as Logo } from '../../../assets/images/delijn.svg';
import { Status, User } from '../../../core/ducks';
import { State } from '../../../core/redux/reducers';
import { PurchaseErrorDialog } from '../../../components';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled.span`
  margin-left: 10px;
`;

const App = ({
  failedBuyingTicket,
  getPurchaseError,
  getApiErrors,
  clearErrors,
  getCustomer,
}: Props) => {
  const [isPurchaseErrorDialogOpen, setIsPurchaseErrorDialogOpen] = useState<
    boolean
  >(false);

  const { t } = useTranslation();

  const handleCloseWebview = () => {
    bridge.closeWebview();
  };

  useEffect(() => {
    setIsPurchaseErrorDialogOpen(failedBuyingTicket);
  }, [failedBuyingTicket]);

  return (
    <AppNavigationProvider>
      <RemoveScroll className='wrapper'>
        <Navigation
          title={
            <TitleContainer>
              <Logo />
              <TitleText>{t('tickets')}</TitleText>
            </TitleContainer>
          }
          left={
            <NavigationBarIconButton onClick={handleCloseWebview}>
              <Back24Icon />
            </NavigationBarIconButton>
          }
        />
        <Routes />
        <PurchaseErrorDialog
          open={isPurchaseErrorDialogOpen}
          getError={getPurchaseError?.toString()}
          getApiErrors={JSON.stringify(getApiErrors)}
          onClose={() => {
            setIsPurchaseErrorDialogOpen(false);
            clearErrors();
          }}
          getCustomer={getCustomer}
        />
      </RemoveScroll>
    </AppNavigationProvider>
  );
};

const mapDispatchToProps = {
  clearErrors: Status.actions.clearErrors,
};

const mapStateToProps = (state: State) => ({
  failedBuyingTicket: Status.selectors.hasError('BUY_TICKET')(state),
  getPurchaseError: Status.selectors.getError('BUY_TICKET')(state),
  getCustomer: User.selectors.getCustomer(state),
  getApiErrors: Status.selectors.getApiErrors(state),
});

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(App);
