import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'pqbc-vas-core';
import { BottomSheetDialog, usePopover } from 'pqbc-vas-design-system';
import { Product } from '../../core/ducks/types';
import * as S from './styles';
import { Tickets } from '../../core/ducks';
import ActivationWarningDialog from '../ActivationWarningDialog';

const mapDispatchToProps = {
  saveNewTicket: Tickets.actions.saveNewTicket,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  onClose: () => void;
  ticketData: {
    product: Product | null;
    amountOfTickets: number;
  };
}

const BuyTicketsBottomSheetDialog = ({
  open,
  onClose,
  ticketData,
  saveNewTicket,
}: Props) => {
  const { t } = useTranslation();

  const { open: openDialog, handleOpen, handleClose } = usePopover();

  const [amount, setAmount] = useState(1);

  return (
    <>
      <BottomSheetDialog
        title={
          ticketData.product
            ? t(`availableProducts.${ticketData.product.id}.name`)
            : t('availableProducts.title')
        }
        open={open}
        onDismiss={onClose}
      >
        <S.DialogContainer>
          <S.Title>{t('buyTicket.title')}</S.Title>
          <S.Subtitle>{t('buyTicket.subtitle')}</S.Subtitle>
          <S.AmountContainer>
            <S.Price>
              {ticketData.product &&
                formatAmount((ticketData.product.price / 100) * amount)}
              <S.Currency>EUR</S.Currency>
            </S.Price>
            <S.ChangeAmountContainer>
              <S.ChangeAmountButton
                disabled={amount === 1}
                decrease
                onClick={() => amount > 1 && setAmount(amount - 1)}
              >
                -
              </S.ChangeAmountButton>
              <S.CurrentAmount>{amount}</S.CurrentAmount>
              <S.ChangeAmountButton
                disabled={amount === 10}
                onClick={() => amount < 10 && setAmount(amount + 1)}
              >
                +
              </S.ChangeAmountButton>
            </S.ChangeAmountContainer>
          </S.AmountContainer>
          <S.SubmitButton
            type='submit'
            onClick={() => {
              saveNewTicket({
                amountOfTickets: amount,
              });
              handleOpen();
            }}
          >
            {t('buyTicket.confirm')}
          </S.SubmitButton>
        </S.DialogContainer>
      </BottomSheetDialog>
      <ActivationWarningDialog
        callback={onClose}
        open={openDialog}
        onCancel={handleClose}
      />
    </>
  );
};

export default connect(
  undefined,
  mapDispatchToProps,
)(BuyTicketsBottomSheetDialog);
