import { put, call, takeEvery, select } from 'redux-saga/effects';
import { ducks, HTTPMethod, isiOS, formatAppVersion } from 'pqbc-vas-core';
import { TicketType } from 'pqbc-vas-native-bridge';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as userSelectors from '../../user/selectors';
import bridge from '../../../../app/bridge';
import TrackingEvents from '../../../../utils/tracking';
import history from '../../../../app/history';
import {
  AvailableProductsApiResponseBody,
  TicketsApiResponseBody,
  BundlesApiResponseBody,
  Ticket,
} from '../types';
import { localStorageContext } from '../constants';

export function* fetchAvailableProductSaga() {
  try {
    const url = `/ticketing/ticket-list`;

    const response: AvailableProductsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      url,
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );

    // a user can buy up to 5 bundles
    const bundles = yield select(selectors.getMyBundles);
    if (bundles.length === 5) {
      const availableProducts = response.content.filter(
        product => !(product.id === 4011),
      );
      yield put(actions.getAvailableProducts.success(availableProducts));
    } else {
      yield put(actions.getAvailableProducts.success(response.content));
    }
  } catch (error) {
    yield put(actions.getAvailableProducts.failure(error));
  }
}

function* buyTicketSaga() {
  try {
    const context = JSON.parse(
      window.localStorage.getItem(localStorageContext) || '',
    );
    let index = 0;

    if (context.isBundle) {
      yield call(ducks.Api.sagas.callApi, '/ticketing/purchase-bundle', {
        method: HTTPMethod.POST,
        headers: {
          '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET,
        },
        body: {
          sku: 'ML',
        },
      });
    } else {
      while (index < context.amountOfTickets) {
        yield call(ducks.Api.sagas.callApi, '/ticketing/sessions', {
          method: HTTPMethod.POST,
          headers: {
            '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET,
          },
          body: {
            ticket_id: context.ticketId,
            device_id: isiOS() ? 'payconiq_ios' : 'payconiq_android',
          },
        });
        // eslint-disable-next-line no-plusplus
        index++;
      }
    }

    bridge.trackEvent(TrackingEvents.SuccessBuyTicket, {
      amount: context.amountOfTickets,
    });
    window.localStorage.removeItem(localStorageContext);
    yield call(fetchAvailableTicketsSaga);
    yield put(actions.buyTicket.success());
    history.push('/overview');
  } catch (error) {
    bridge.trackEvent(TrackingEvents.FailedBuyTicket);
    yield put(actions.buyTicket.failure(error));
  }
}

function* activateTicketSaga() {
  try {
    const ticketData = yield select(selectors.getActivateTicketData);
    let index = 0;

    while (index < ticketData.amountOfTickets) {
      yield call(ducks.Api.sagas.callApi, '/ticketing/sessions', {
        method: HTTPMethod.POST,
        headers: {
          '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET,
        },
        body: {
          ticket_id: ticketData.bundle.ticket_type_id,
          bundle_serial: ticketData.bundle.bundle_serial,
          device_id: isiOS() ? 'payconiq_ios' : 'payconiq_android',
        },
      });
      // eslint-disable-next-line no-plusplus
      index++;
    }

    yield call(fetchAvailableTicketsSaga);
    bridge.trackEvent(TrackingEvents.SuccessActivation, {
      amount: ticketData.amountOfTickets,
    });
    yield put(actions.startTicketActivation.success());
    history.push('/overview');
  } catch (error) {
    bridge.trackEvent(TrackingEvents.FailedActivation);
    yield put(actions.startTicketActivation.failure(error));
  }
}

export function* startPayconiqPaymentSaga() {
  try {
    const ticketData = yield select(selectors.getNewTicketData);
    const customer = yield select(userSelectors.getCustomer);

    bridge.trackEvent(TrackingEvents.CompleteBuyTicket, {
      amount: ticketData.amountOfTickets,
    });
    const appVersion = formatAppVersion(yield bridge.getAppVersion());
    if (appVersion >= 5080) {
      bridge.markInUse();
    }

    const context = {
      amountOfTickets: ticketData.amountOfTickets,
      ticketId: ticketData.product.id,
      isBundle: ticketData.product.id === 4011,
    };

    window.localStorage.setItem(localStorageContext, JSON.stringify(context));

    yield bridge.startPayconiqPaymentFlow({
      productProfileId: process.env.REACT_APP_PAYCONIQ_PROFILE_ID,
      amount: ticketData.amountOfTickets * ticketData.product.price,
      reference: `customer:${customer.number}`,
    });
  } catch (error) {
    bridge.trackEvent(TrackingEvents.FailedBuyTicket);
    yield put(actions.startPayconiqPayment.failure(error));
  }
}

export function* fetchAvailableTicketsSaga() {
  try {
    const responseRunning: TicketsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/sessions?type=ticketing&status=running&items_per_page=100',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    const responseBundle: BundlesApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/ticketing/ticket-bundles',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );

    const tickets = {
      activated: responseRunning.content,
      bundles: responseBundle.content,
    };

    const ticketsToSync: Ticket[] = responseRunning.content;

    yield put(actions.getMyTickets.success(tickets));
    bridge.syncTickets({ type: TicketType.DeLijn, tickets: ticketsToSync });
  } catch (error) {
    yield put(actions.getMyTickets.failure(error));
  }
}

export function* TicketsSaga() {
  yield takeEvery(actions.getMyTickets.request, fetchAvailableTicketsSaga);
  yield takeEvery(actions.startTicketActivation.request, activateTicketSaga);
  yield takeEvery(
    actions.getAvailableProducts.request,
    fetchAvailableProductSaga,
  );
  yield takeEvery(
    actions.startPayconiqPayment.request,
    startPayconiqPaymentSaga,
  );
  yield takeEvery(actions.buyTicket.request, buyTicketSaga);
}
