import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll, {
  Props as InfiniteScrollProps,
} from 'react-infinite-scroll-component';

import * as S from './styles';

type Props = Omit<InfiniteScrollProps, 'loader'>;

const InfiniteScrollPullRefresh = (props: Props) => {
  const { t } = useTranslation();
  return (
    <InfiniteScroll
      loader={<S.LoadMoreSpinner />}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={
        <S.Text style={{ textAlign: 'center' }}>
          &#8595; {t('pullToRefresh')}
        </S.Text>
      }
      releaseToRefreshContent={
        <S.Text style={{ textAlign: 'center' }}>
          &#8593; {t('releaseToRefresh')}
        </S.Text>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default InfiniteScrollPullRefresh;
