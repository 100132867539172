import reducer from './reducers';
import * as selectors from './selectors';
import * as actions from './actions';
import * as sagas from './sagas';
import * as types from './types';
import * as constants from './constants';

export default {
  actions,
  types,
  reducer,
  constants,
  sagas,
  selectors,
};
