import { connect } from 'react-redux';
import OverviewView from './OverviewView';
import { Status, Tickets, User } from '../../core/ducks';
import { State } from '../../core/redux/reducers';

const mapStateToProps = (state: State) => ({
  myActivatedTickets: Tickets.selectors.getMyActivatedTickets(state),
  myBundles: Tickets.selectors.getMyBundles(state),
  availableProducts: Tickets.selectors.getAvailableProducts(state),
  isLoadingProducts: Status.selectors.isLoading(
    Tickets.constants.GET_AVAILABLE_PRODUCTS,
  )(state),
  isActivating: Status.selectors.isLoading(
    Tickets.constants.START_TICKET_ACTIVATION,
  )(state),
  ticketData: Tickets.selectors.getNewTicketData(state),
  cannotFetchAvailableProducts: Tickets.selectors.getCannotFetchAvailableProducts(
    state,
  ),
  isLoading: Status.selectors.isLoading('GET_MY_TICKETS')(state),
  isBuyingTickets: Status.selectors.isLoading('BUY_TICKET')(state),
});

const mapDispatchToProps = {
  getAvailableProducts: Tickets.actions.getAvailableProducts.request,
  saveNewTicket: Tickets.actions.saveNewTicket,
  getCustomerProfile: User.actions.getCustomerProfile.request,
  startPayment: Tickets.actions.startPayconiqPayment.request,
  getMyTickets: Tickets.actions.getMyTickets.request,
  buyTicketRequest: Tickets.actions.buyTicket.request,
};

export type OverviewProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OverviewView);
