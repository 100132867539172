import { Reducer } from 'redux';
import { merge } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions';
import { Product } from '../../types';
import { Ticket, Bundle } from '../types';

export type TicketsState = {
  activated: Ticket[];
  bundles: Bundle[];
  availableProducts: Product[];
  cannotFetchAvailableProducts: boolean;
  newTicket: {
    product: Product | null;
    amountOfTickets: number;
  };
  activateTicket: {
    bundle: Bundle | null;
    amountOfTickets: number;
  };
};

export const initialState: TicketsState = {
  activated: [],
  bundles: [],
  availableProducts: [],
  cannotFetchAvailableProducts: false,
  newTicket: {
    product: null,
    amountOfTickets: 0,
  },
  activateTicket: {
    bundle: null,
    amountOfTickets: 0,
  },
};

const TicketsReducer: Reducer<TicketsState, ActionType<typeof actions>> = (
  state = initialState,
  action,
): TicketsState => {
  switch (action.type) {
    case getType(actions.getMyTickets.success): {
      return {
        ...state,
        activated: action.payload.activated,
        bundles: action.payload.bundles,
      };
    }

    case getType(actions.getAvailableProducts.request): {
      return {
        ...state,
        availableProducts: [],
        cannotFetchAvailableProducts: false,
      };
    }
    case getType(actions.getAvailableProducts.success): {
      return {
        ...state,
        availableProducts: action.payload,
      };
    }
    case getType(actions.getAvailableProducts.failure): {
      return {
        ...state,
        cannotFetchAvailableProducts: true,
      };
    }
    case getType(actions.saveNewTicket): {
      return {
        ...state,
        newTicket: { ...merge(state.newTicket, action.payload) },
      };
    }
    case getType(actions.activateTicket): {
      return {
        ...state,
        activateTicket: { ...merge(state.activateTicket, action.payload) },
      };
    }
    case getType(actions.clearNewTicket): {
      return {
        ...state,
        newTicket: {
          product: null,
          amountOfTickets: 0,
        },
      };
    }
    default:
      return state;
  }
};

export default TicketsReducer;
