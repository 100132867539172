import React, { useState, useEffect } from 'react';
import {
  Dialog,
  PopoverContent,
  Card,
  ExternalLink,
} from 'pqbc-vas-design-system';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import * as S from './styles';
import bridge from '../../app/bridge';
import { State } from '../../core/redux/reducers';
import { User, Auth } from '../../core/ducks';
import TrackingEvents from '../../utils/tracking';

const UpdatedTermsAndConditionsDialog: React.FC<Props> = ({
  fetchLatestConsentStatus,
  isLatestAccepted,
  isLoggedIn,
  accept,
}) => {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchLatestConsentStatus();
    }
  }, [isLoggedIn, fetchLatestConsentStatus]);

  const handleOpenTermsAndConditions = () => {
    bridge.trackEvent(TrackingEvents.OpenTermsAndConditions);
    const url = t('settings.termsAndConditions.url');
    bridge.openUrl(url);
  };

  const handleAccept = () => {
    setIsAccepted(true);
    accept();
  };

  const handleDismiss = () => {
    bridge.closeWebview();
  };

  return (
    <Dialog
      open={!isLatestAccepted && !isAccepted}
      onDismiss={handleDismiss}
      data-testid='updatedTermsAndConditionsDialog'
    >
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <S.TermsAndConditionsIcon />
            <Dialog.Title>{t('updatedTerms.title')}</Dialog.Title>
            <Dialog.Message>
              <Trans
                i18nKey='updatedTerms.description'
                components={[
                  <ExternalLink onClick={handleOpenTermsAndConditions} />,
                ]}
              />
            </Dialog.Message>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Button color='success' onClick={handleAccept}>
                {t('updatedTerms.accept')}
              </S.Button>
              <S.Button color='secondary' outline onClick={handleDismiss}>
                {t('updatedTerms.decline')}
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

const mapStateToProps = (state: State) => ({
  isLatestAccepted: User.selectors.isLatestTermsAndConditionsAccepted(state),
  isLoggedIn: Auth.selectors.isLoggedIn(state),
});

const mapDispatchToProps = {
  accept: User.actions.acceptLatestTermsAndConditions.request,
  fetchLatestConsentStatus:
    User.actions.fetchTermsAndConditionsConsentStatus.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdatedTermsAndConditionsDialog);
