import styled from 'styled-components';

export const Mailto = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
`;

export const ListItemWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
`;
