import { fork } from 'redux-saga/effects';

import * as Ducks from '../ducks';

function* main() {
  yield fork(Ducks.Auth.sagas.watchAuthentication);
  yield fork(Ducks.Tickets.sagas.TicketsSaga);
  yield fork(Ducks.User.sagas.UserSaga);
}

export default main;
