import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BottomSheetDialog,
  ListItem,
  ListItemIcon,
  Faq24Icon,
  ListItemLabel,
  ListItemSubtitle,
  ListItemDivider,
} from 'pqbc-vas-design-system';
import { ReactComponent as Logo4411 } from '../../assets/images/logo-4411-small.svg';
import { Tickets } from '../../core/ducks';
import * as S from './styles';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';

const mapDispatchToProps = {
  startPayment: Tickets.actions.startPayconiqPayment.request,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
}

const HelpBottomSheetDialog = ({ open, onClose, children, title }: Props) => {
  const { t } = useTranslation();

  const mailToLink = `mailto:${t(
    'help.contactSupport.email',
  )}?subject=${encodeURI(
    t('help.contactSupport.template.subject'),
  )}&body=${encodeURI(t('help.contactSupport.template.body'))}`;

  const handleFaqClick = () => {
    const url = t('help.faq.url');
    bridge.trackEvent(TrackingEvents.OpenFAQ);
    bridge.openUrl(url);
  };

  const handleContactSupport = () =>
    bridge.trackEvent(TrackingEvents.ContactSupport);

  return (
    <BottomSheetDialog
      title={title || t('help.title')}
      open={open}
      onDismiss={onClose}
    >
      <ListItem onClick={handleFaqClick}>
        <ListItemIcon>
          <Faq24Icon />
        </ListItemIcon>
        <ListItemLabel>{t('help.faq.title')}</ListItemLabel>
      </ListItem>
      <ListItemDivider />
      <S.Mailto href={mailToLink} onClick={handleContactSupport}>
        <ListItem>
          <ListItemIcon>
            <Logo4411 />
          </ListItemIcon>
          <S.ListItemWithSubtitle>
            <ListItemLabel>{t('help.contactSupport.title')}</ListItemLabel>
            <ListItemSubtitle>
              {t('help.contactSupport.subtitle')}
            </ListItemSubtitle>
          </S.ListItemWithSubtitle>
        </ListItem>
      </S.Mailto>
      {children}
    </BottomSheetDialog>
  );
};

export default connect(undefined, mapDispatchToProps)(HelpBottomSheetDialog);
