enum TrackingEvents {
  StartLogin = 'start_login',
  CompleteLogin = 'completed_login',
  Logout = 'logout_start',
  LogoutComplete = 'logout_complete',
  ClickHelp = 'help',
  OpenPrivacyPolicy = 'privacy_policy',
  OpenTermsAndConditions = 't&c',
  ContactSupport = 'support',
  OpenFAQ = 'faq',
  OpenSettings = 'open_settings',
  StartBuyTicket = 'start_buy_ticket',
  ChooseTicket = 'choose_ticket',
  CompleteBuyTicket = 'complete_buy_ticket',
  FailedBuyTicket = 'failed_buy_ticket',
  SuccessBuyTicket = 'success_buy_ticket',
  ShowTicketDetail = 'ticket_detail',
  StartActivation = 'start_activation',
  CompleteActivation = 'complete_activation',
  SuccessActivation = 'success_activation',
  FailedActivation = 'failed_activation',
}

export default TrackingEvents;
