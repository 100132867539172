import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from '../assets/locales/en.json';
import langNl from '../assets/locales/nl.json';
import langFr from '../assets/locales/fr.json';
import bridge from './bridge';

const urlParams = new URLSearchParams(window.location.search);
const showKeys = urlParams.has('showKeys');
const urlLanguage = urlParams.get('lang');
const defaultLanguage = 'en';
const translationResources = {
  en: {
    app: langEn,
  },
  fr: {
    app: langFr,
  },
  nl: {
    app: langNl,
  },
};

export type Language = keyof typeof translationResources;

export function isLanguageAvailable(language: string) {
  return Object.keys(translationResources).includes(language);
}

export const setNativeLanguage = async () => {
  if (showKeys) {
    return i18n.changeLanguage('dev');
  }
  if (urlLanguage && isLanguageAvailable(urlLanguage)) {
    return i18n.changeLanguage(urlLanguage);
  }
  const nativeLanguage = await bridge.getLanguage();
  const language =
    nativeLanguage && isLanguageAvailable(nativeLanguage)
      ? nativeLanguage
      : defaultLanguage;
  return i18n.changeLanguage(language);
};

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: defaultLanguage,
  fallbackLng: showKeys ? false : defaultLanguage,
  defaultNS: 'app',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
