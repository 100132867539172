import React from 'react';
import { Dialog, Card, PopoverContent } from 'pqbc-vas-design-system';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

interface Props {
  open: boolean;
  onLogout: () => void;
  onDismiss: () => void;
}

const LogoutWarningDialog: React.FC<Props> = ({
  open,
  onDismiss,
  onLogout,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onDismiss={onDismiss}>
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <Dialog.Title>{t('settings.logout.warning.title')}</Dialog.Title>
            <S.DialogMessage>
              {t('settings.logout.warning.description')}
            </S.DialogMessage>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Button color='success' onClick={onDismiss}>
                {t('settings.logout.warning.stay')}
              </S.Button>
              <S.Button color='error' outline onClick={onLogout}>
                {t('settings.logout.warning.leave')}
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

export default LogoutWarningDialog;
