import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as S from './styles';
import { UpdatedTermsAndConditionsDialog } from '../../../components';
import PrivateRoute from '../PrivateRoute';
import { Onboarding, Overview } from '../../../screens';

const Routes = () => (
  <S.Main>
    <UpdatedTermsAndConditionsDialog />
    <Switch>
      <Route exact path='/' component={Onboarding} />
      <PrivateRoute exact path='/overview' component={Overview} />
      <Redirect from='*' to='/' />
    </Switch>
  </S.Main>
);

export default Routes;
