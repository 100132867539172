import { configureStore as defaultConfigureStore } from 'pqbc-vas-core';
import { persistStore } from 'redux-persist';

import { PreloadedState } from 'redux';
import rootSaga from './sagas';
import rootReducer, { State } from './reducers';

const configureStore = (initialState?: PreloadedState<State>) => {
  const store = defaultConfigureStore(rootReducer, rootSaga, initialState);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
