import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router';

import { State } from '../../../core/redux/reducers';
import { Auth } from '../../../core/ducks';

interface Props extends RouteProps {
  isLoggedIn: boolean;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { isLoggedIn } = rest;

  const render = (props: RouteComponentProps) => {
    if (isLoggedIn && Component) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }
    return <Redirect to='/' />;
  };

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={render}
    />
  );
};

const mapStateToProps = (state: State) => ({
  isLoggedIn: Auth.selectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(PrivateRoute);
