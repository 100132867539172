import { HTTPMethod, isiOS, isAndroid } from 'pqbc-vas-core';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as ducks from '../..';
import bridge from '../../../../app/bridge';
import TrackingEvents from '../../../../utils/tracking';
import { TokenResponseBody } from '../types';

export function* getAccessToken(
  action: ReturnType<typeof ducks.Auth.actions.getAccessToken.request>,
) {
  try {
    const codeVerifier = yield select(ducks.Auth.selectors.getCodeVerifier);

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('grant_type', 'authorization_code');
    urlSearchParams.append(
      'client_id',
      process.env.REACT_APP_CLIENT_ID as string,
    );
    urlSearchParams.append(
      'client_secret',
      process.env.REACT_APP_CLIENT_SECRET as string,
    );
    urlSearchParams.append(
      'redirect_uri',
      process.env.REACT_APP_REDIRECT_URL as string,
    );
    urlSearchParams.append('code', action.payload.code);
    urlSearchParams.append('code_verifier', codeVerifier);

    const responseBody: TokenResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/oauth/token',
      {
        body: urlSearchParams,
        method: HTTPMethod.POST,
      },
      { skipAuthentication: true },
    );

    bridge.trackEvent(TrackingEvents.CompleteLogin);

    yield put(
      ducks.Auth.actions.getAccessToken.success({
        accessToken: responseBody.content.access_token,
      }),
    );
  } catch (error) {
    yield put(ducks.Auth.actions.getAccessToken.failure(error));
  }
}

export function* revokeAccessToken() {
  try {
    if (process.env.REACT_APP_ENV === 'PRODUCTION' || isiOS() || isAndroid()) {
      yield call(ducks.Api.sagas.callApi, `/oauth/revoke-token`, {
        method: HTTPMethod.POST,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      });
    }
    yield put(ducks.Auth.actions.logout.success());
  } catch (error) {
    yield put(ducks.Auth.actions.logout.failure(error));
  }
}

export function* watchAuthentication() {
  yield takeEvery(ducks.Auth.actions.getAccessToken.request, getAccessToken);
  yield takeEvery(ducks.Auth.actions.logout.request, revokeAccessToken);
}
