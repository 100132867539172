import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BottomSheetDialog,
  ListItem,
  ListItemDivider,
  Flex,
} from 'pqbc-vas-design-system';
import { formatAmount } from 'pqbc-vas-core';
import TrackingEvents from '../../utils/tracking';
import bridge from '../../app/bridge';
import { productIds, productType } from '../../core/ducks/tickets/constants';
import { Product } from '../../core/ducks/types';
import * as S from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  availableProducts: Product[];
  isLoading: boolean;
  onSelect: (product: Product) => void;
  cannotFetchAvailableProducts: boolean;
}

const AvailableProductsBottomSheetDialog = ({
  open,
  onClose,
  availableProducts,
  cannotFetchAvailableProducts,
  isLoading,
  onSelect,
}: Props) => {
  const { t } = useTranslation();

  const handleSelectTicket = (product: Product) => {
    if (product.id === productIds.Standard) {
      bridge.trackEvent(TrackingEvents.ChooseTicket, {
        ticket_type: productType.Standard,
      });
    }
    if (product.id === productIds.Bundle) {
      bridge.trackEvent(TrackingEvents.ChooseTicket, {
        ticket_type: productType.Bundle,
      });
    }
    onSelect(product);
    onClose();
  };

  return (
    <>
      <BottomSheetDialog
        title={t('availableProducts.title')}
        open={open}
        onDismiss={onClose}
      >
        {isLoading && (
          <S.LoadingContainer>
            <S.Spinner />
            <S.LoadingDescription>
              {t('availableProducts.loading')}
            </S.LoadingDescription>
          </S.LoadingContainer>
        )}
        {cannotFetchAvailableProducts && (
          <S.LoadingContainer>
            <S.LoadingDescription>
              {t('availableProducts.error')}
            </S.LoadingDescription>
          </S.LoadingContainer>
        )}
        {!isLoading &&
          !cannotFetchAvailableProducts &&
          availableProducts.map(product => (
            <React.Fragment key={product.id}>
              <ListItem
                onClick={() => {
                  handleSelectTicket(product);
                }}
              >
                <S.Product>
                  <S.ProductDescription>
                    <S.ProductTitle>
                      {t(`availableProducts.${product.id}.name`, {
                        defaultValue: product.name,
                      })}
                    </S.ProductTitle>
                    <S.ProductSubtitle>
                      {t(`availableProducts.${product.id}.description`, {
                        defaultValue: '',
                      })}
                    </S.ProductSubtitle>
                  </S.ProductDescription>
                  <Flex>
                    {formatAmount(product.price / 100)}{' '}
                    <S.Currency>EUR</S.Currency>
                  </Flex>
                </S.Product>
              </ListItem>
              <ListItemDivider />
            </React.Fragment>
          ))}
      </BottomSheetDialog>
    </>
  );
};

export default AvailableProductsBottomSheetDialog;
