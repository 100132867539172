import { connect } from 'react-redux';

import { Auth } from '../../core/ducks';
import { State } from '../../core/redux/reducers';
import OnboardingView from './OnboardingView';

const mapStateToProps = (state: State) => ({
  isLoggedIn: Auth.selectors.isLoggedIn(state),
  oAuthCodeChallenge: Auth.selectors.getCodeChallenge(state),
});

const mapDispatchToProps = {
  getAccessToken: Auth.actions.getAccessToken.request,
  setAccessTokenResponse: Auth.actions.getAccessToken.success,
};

export type OnboardingProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingView);
