import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Ticket } from '../../core/ducks/tickets/types';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import * as S from './styles';

const ActivatedTicket = ({ ticket }: { ticket: Ticket }) => {
  const { t } = useTranslation();
  const expiration = new Date(ticket.time_stop);

  const calculateMinutesLeft = (date: Date) => {
    const diff = Math.max(
      0,
      Math.round((date.getTime() - Date.now()) / 1000 / 60),
    );

    return diff || null;
  };

  const [minutes, setMinutes] = useState(calculateMinutesLeft(expiration));

  useEffect(() => {
    const timer = setInterval(() => {
      setMinutes(calculateMinutesLeft(expiration));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [expiration, minutes]);

  const handleTicketDetail = (ticketId: string) => {
    bridge.trackEvent(TrackingEvents.ShowTicketDetail, {
      start_from: 'dl_overview',
    });
    bridge.openTicket(ticketId.toString());
  };

  return (
    <S.TicketContainer
      onClick={() => {
        handleTicketDetail(ticket.id);
      }}
    >
      <S.TicketFlex>
        <S.Description>
          <S.Title>{t(`overview.ticket.name`)}</S.Title>
          <S.ValidUntil>
            {minutes
              ? `${t(`overview.ticket.description`)} `
              : `${t(`overview.ticket.expiredAt`)} `}
            {new Date(ticket.time_stop).toLocaleTimeString('en-GB', {
              // 'en-GB' to avoid PM/AM
              hour: '2-digit',
              minute: '2-digit',
            })}
          </S.ValidUntil>
        </S.Description>
        {minutes && <S.Countdown>{`${minutes} min`}</S.Countdown>}
      </S.TicketFlex>
    </S.TicketContainer>
  );
};

export default ActivatedTicket;
