import { combineReducers } from 'redux';
import { StatusRootState, AuthRootState } from 'pqbc-vas-core';
import { Auth, Tickets, Status, User } from '../ducks';
import { TicketsState } from '../ducks/tickets/reducers';
import { UserState } from '../ducks/user/reducers';

export interface State extends AuthRootState, StatusRootState {
  tickets: TicketsState;
  user: UserState;
}

const rootReducer = combineReducers<State>({
  auth: Auth.reducer,
  status: Status.reducer,
  tickets: Tickets.reducer,
  user: User.reducer,
});

export default rootReducer;
