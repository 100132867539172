import styled from 'styled-components';

import {
  Flex,
  TermsandconditionsIllustration,
  Button as DSButton,
} from 'pqbc-vas-design-system';

export const TermsAndConditionsIcon = styled(TermsandconditionsIllustration)`
  width: 100%;
  height: auto;
  max-height: 200px;
  margin-bottom: 18px;
`;

export const ButtonGroup = styled(Flex)`
  & > * + * {
    margin-top: 8px;
  }
`;

export const Button = styled(DSButton)`
  width: 100%;
`;
