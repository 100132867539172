import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  overflow: auto;
  flex: 1;
  z-index: 1;
  margin-top: calc(var(--header-offset) * -1);
  margin-left: auto;
  margin-right: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 8px;
  @supports (padding: max(0px)) {
    padding-bottom: max(8px, env(safe-area-inset-bottom));
  }
`;
