export const GET_AVAILABLE_PRODUCTS = 'GET_AVAILABLE_PRODUCTS';
export const START_PAYCONIQ_PAYMENT = 'START_PAYCONIQ_PAYMENT';
export const START_TICKET_ACTIVATION = 'START_TICKET_ACTIVATION';

export enum productIds {
  Standard = 4007,
  Bundle = 4011,
}

export enum productType {
  Standard = 'm-ticket',
  Bundle = 'm-card10',
}

export const localStorageContext = 'DELIJN_TICKET_CONTEXT';
