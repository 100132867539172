import styled from 'styled-components';
import { Button } from 'pqbc-vas-design-system';

export const DialogContainer = styled.div`
  display: flex;
  margin: 20px 15px;
  flex-direction: column;
`;

export const Description = styled.p`
  margin: 5px 0;
  font-weight: 300;
  font-size: 16px;
  color: ${props => props.theme.colors.neutral600};
`;

export const AmountContainer = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.secondary500};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const ChangeAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ChangeAmountButton = styled.div<{
  decrease?: boolean;
  disabled?: boolean;
}>`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 30px;
  line-height: 38px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: ${props =>
    props.decrease ? '1px solid #E7E4DD' : '1px solid #FF89B0'};
  color: ${props =>
    props.decrease ? props.theme.colors.neutral600 : '#FF4785'};
  outline: none;
`;

export const CurrentAmount = styled.div`
  font-size: 30px;
  font-weight: 600;
  width: 50px;
  line-height: 38px;
  text-align: center;
  color: ${props => props.theme.colors.secondary500};
`;

export const SubmitButton = styled(Button).attrs({
  color: 'success',
})`
  width: 100%;
  margin-top: 10px;
`;
