import { usePopover } from 'pqbc-vas-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import bridge from '../../app/bridge';
import { Bundle } from '../../core/ducks/tickets/types';
import TrackingEvents from '../../utils/tracking';
import * as S from './styles';
import { ActivateTicketsBottomSheetDialog } from '../../components';

const BundleTicket = ({ bundles }: { bundles: Bundle[] }) => {
  const { t } = useTranslation();
  const {
    open: openActivateTickets,
    handleOpen: handleActivateTickets,
    handleClose: handleCloseActivateTickets,
  } = usePopover();

  const handleActivateTicket = () => {
    bridge.trackEvent(TrackingEvents.StartActivation);
    handleActivateTickets();
  };

  const getTotalTicketAmount = () => {
    return bundles.reduce((amount, bundle) => {
      return amount + bundle.balance;
    }, 0);
  };

  return (
    <>
      <S.BundleContainer onClick={handleActivateTicket}>
        <S.TicketFlex>
          <S.Description>
            <S.Title>{t(`overview.bundle.name`)}</S.Title>
            {bundles.length > 1 ? (
              <S.ValidUntil>
                {bundles.length} {t(`overview.bundle.nbCardsAvailable`)}
              </S.ValidUntil>
            ) : (
              <S.ValidUntil>
                {t(`overview.bundle.expiresOn`)}{' '}
                {new Date(bundles[0].availability_end).toLocaleDateString()}
              </S.ValidUntil>
            )}
          </S.Description>
          <S.Countdown>
            {getTotalTicketAmount()} {t(`overview.bundle.tickets`)}
          </S.Countdown>
        </S.TicketFlex>
      </S.BundleContainer>
      <ActivateTicketsBottomSheetDialog
        open={openActivateTickets}
        onClose={handleCloseActivateTickets}
        bundle={bundles[0]}
        getTicketAmount={getTotalTicketAmount}
      />
    </>
  );
};

export default BundleTicket;
