import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BottomSheetDialog } from 'pqbc-vas-design-system';
import * as S from './styles';
import { Tickets } from '../../core/ducks';
import { Bundle } from '../../core/ducks/tickets/types';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';

const mapDispatchToProps = {
  activateTicket: Tickets.actions.activateTicket,
  startActivation: Tickets.actions.startTicketActivation.request,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  onClose: () => void;
  bundle: Bundle;
  getTicketAmount: () => number;
}

const ActivateTicketsBottomSheetDialog = ({
  open,
  onClose,
  bundle,
  activateTicket,
  startActivation,
  getTicketAmount,
}: Props) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(1);
  const max = Math.min(10, getTicketAmount());

  return (
    <>
      <BottomSheetDialog
        title={t('activateTicket.title')}
        open={open}
        onDismiss={onClose}
      >
        <S.DialogContainer>
          <S.Description>{t('activateTicket.description')}</S.Description>
          <S.AmountContainer>
            {t('activateTicket.amount')}
            <S.ChangeAmountContainer>
              <S.ChangeAmountButton
                disabled={amount === 1}
                decrease
                onClick={() => amount > 1 && setAmount(amount - 1)}
              >
                -
              </S.ChangeAmountButton>
              <S.CurrentAmount>{amount}</S.CurrentAmount>
              <S.ChangeAmountButton
                disabled={amount === max}
                onClick={() => amount < max && setAmount(amount + 1)}
              >
                +
              </S.ChangeAmountButton>
            </S.ChangeAmountContainer>
          </S.AmountContainer>
          <S.SubmitButton
            type='submit'
            onClick={() => {
              bridge.trackEvent(TrackingEvents.CompleteActivation, { amount });
              activateTicket({
                bundle,
                amountOfTickets: amount,
              });
              startActivation();
              onClose();
            }}
          >
            {t('activateTicket.confirm')}
          </S.SubmitButton>
        </S.DialogContainer>
      </BottomSheetDialog>
    </>
  );
};

export default connect(
  undefined,
  mapDispatchToProps,
)(ActivateTicketsBottomSheetDialog);
