import { persistReducer, PersistConfig } from 'redux-persist';
import { Reducer } from 'redux';
import { ducks, storage, AuthState } from 'pqbc-vas-core';
import { ActionType } from 'typesafe-actions';
import * as sagas from './sagas';

const createPersistedReducer = (
  reducer: Reducer<AuthState, ActionType<typeof ducks.Auth.actions>>,
) => {
  const persistConfig: PersistConfig<AuthState> = {
    key: 'auth',
    storage,
    whitelist: ['accessToken'],
  };
  return persistReducer(persistConfig, reducer);
};

export default {
  ...ducks.Auth,
  sagas,
  reducer: createPersistedReducer(ducks.Auth.reducer),
};
