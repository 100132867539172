import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Card, PopoverContent } from 'pqbc-vas-design-system';
import { useTranslation } from 'react-i18next';
import { Tickets } from '../../core/ducks';
import * as S from './styles';

const mapDispatchToProps = {
  startPayment: Tickets.actions.startPayconiqPayment.request,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  callback: () => void;
  onCancel: () => void;
}

const ActivationWarningDialog = ({
  open,
  onCancel,
  callback,
  startPayment,
}: Props) => {
  const { t } = useTranslation();

  const handlePayment = () => {
    startPayment();
    callback();
    onCancel();
  };

  return (
    <Dialog open={open} onDismiss={onCancel}>
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <Dialog.Title>{t('activationWarning.title')}</Dialog.Title>
            <S.DialogMessage>
              {t('activationWarning.description')}
            </S.DialogMessage>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Button color='success' onClick={handlePayment}>
                {t('activationWarning.accept')}
              </S.Button>
              <S.Button color='secondary' outline onClick={onCancel}>
                {t('activationWarning.decline')}
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

export default connect(undefined, mapDispatchToProps)(ActivationWarningDialog);
