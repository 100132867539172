import {
  NavigationBarButton,
  Settings24Icon,
  useAppNavigation,
  usePopover,
  CardContent,
  Spinner,
  Flex,
} from 'pqbc-vas-design-system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bridge from '../../app/bridge';
import {
  AvailableProductsBottomSheetDialog,
  BuyTicketsBottomSheetDialog,
  InfiniteScrollPullRefresh,
} from '../../components';
import TrackingEvents from '../../utils/tracking';
import TicketImage from '../../assets/images/ticket.svg';
import { OverviewProps } from './OverviewContainer';
import ActivatedTicket from './ActivatedTicket';
import BundleTicket from './BundleTicket';
import * as S from './styles';
import { Ticket } from '../../core/ducks/tickets/types';
import { Product } from '../../core/ducks/types';
import SettingsBottomSheetDialog from '../../components/SettingsBottomSheetDialog';

export const sortTicketsOnExpirationDate = (tickets: Ticket[]) => {
  return tickets.sort((a, b) => {
    return new Date(a.time_stop).getTime() - new Date(b.time_stop).getTime();
  });
};

const OverviewView = ({
  getMyTickets,
  getAvailableProducts,
  availableProducts,
  isLoadingProducts,
  myActivatedTickets,
  myBundles,
  isLoading,
  isActivating,
  isBuyingTickets,
  cannotFetchAvailableProducts,
  ticketData,
  saveNewTicket,
  getCustomerProfile,
  startPayment,
  buyTicketRequest,
}: OverviewProps) => {
  useAppNavigation({
    right: (
      <NavigationBarButton>
        <Settings24Icon />
      </NavigationBarButton>
    ),
  });
  const {
    open: openSettings,
    handleOpen: handleOpenSettings,
    handleClose: handleCloseSettings,
  } = usePopover();
  const {
    open: openAvailableProducts,
    handleOpen: handleOpenAvailableProducts,
    handleClose: handleCloseAvailableProducts,
  } = usePopover();
  const {
    open: openBuyTickets,
    handleOpen: handleOpenBuyTickets,
    handleClose: handleCloseBuyTickets,
  } = usePopover();
  const { t } = useTranslation();

  const handlePullToRefresh = () => {
    getMyTickets();
  };

  useEffect(() => {
    const startListeningForPaymentCallback = async () => {
      const paymentSuccess = await bridge.onPaymentFinished();
      // Each time an event is received we restart this listener to make sure future events are captured too.
      startListeningForPaymentCallback();

      if (paymentSuccess) {
        buyTicketRequest();
      }
    };
    startListeningForPaymentCallback();
  }, [buyTicketRequest]);

  useEffect(() => {
    getCustomerProfile();
  }, [getCustomerProfile]);

  useEffect(() => {
    getMyTickets();
  }, [getMyTickets]);

  const handleOpen = () => {
    handleOpenSettings();
    bridge.trackEvent(TrackingEvents.OpenSettings);
  };

  useAppNavigation(
    {
      right: (
        <NavigationBarButton onClick={handleOpen}>
          <Settings24Icon />
        </NavigationBarButton>
      ),
      extension: (
        <>
          {myBundles.length > 0 && (
            <>
              <BundleTicket bundles={myBundles} />
              {myActivatedTickets.length > 0 && (
                <S.ActivatedTickets>
                  {t('overview.activatedTickets')}
                </S.ActivatedTickets>
              )}
            </>
          )}
        </>
      ),
    },
    [myBundles, myActivatedTickets],
  );

  return isLoading || isBuyingTickets || isActivating ? (
    <S.NoTicketContainer>
      <CardContent>
        <Spinner />
        <S.EmptyViewText>{t('overview.loading')}</S.EmptyViewText>
      </CardContent>
    </S.NoTicketContainer>
  ) : (
    <Flex flexDirection='column' flex='auto' justifyContent='space-between'>
      {myActivatedTickets.length > 0 ? (
        <InfiniteScrollPullRefresh
          dataLength={myActivatedTickets.length}
          next={() => {}}
          hasMore={false}
          refreshFunction={handlePullToRefresh}
        >
          <S.TicketsContainer>
            {sortTicketsOnExpirationDate(myActivatedTickets).map(
              (ticket: Ticket) => (
                <ActivatedTicket key={ticket.id} ticket={ticket} />
              ),
            )}
          </S.TicketsContainer>
        </InfiniteScrollPullRefresh>
      ) : (
        <>
          {myBundles.length > 0 ? (
            <S.NoTicketContainer>
              <CardContent>
                <S.NoTicketsImage src={TicketImage} objectFit='contain' />
                <S.EmptyViewText>
                  {t('overview.noActivatedTickets')}
                </S.EmptyViewText>
              </CardContent>
            </S.NoTicketContainer>
          ) : (
            <S.NoTicketContainer>
              <CardContent>
                <S.NoTicketsImage src={TicketImage} objectFit='contain' />
                <S.EmptyViewText>{t('overview.noTickets')}</S.EmptyViewText>
              </CardContent>
            </S.NoTicketContainer>
          )}
        </>
      )}
      <S.GetTicketButton
        color='success'
        onClick={() => {
          getAvailableProducts();
          bridge.trackEvent(TrackingEvents.StartBuyTicket);
          handleOpenAvailableProducts();
        }}
      >
        {t('overview.getTicket')}
      </S.GetTicketButton>

      <AvailableProductsBottomSheetDialog
        open={openAvailableProducts}
        onClose={handleCloseAvailableProducts}
        availableProducts={availableProducts}
        isLoading={isLoadingProducts}
        cannotFetchAvailableProducts={cannotFetchAvailableProducts}
        onSelect={(product: Product) => {
          if (product.id === 4007) {
            // Buy m-ticket
            saveNewTicket({ product });
            handleCloseAvailableProducts();
            handleOpenBuyTickets();
          } else {
            // Buy bundle
            saveNewTicket({ product, amountOfTickets: 1 });
            startPayment();
          }
        }}
      />
      <BuyTicketsBottomSheetDialog
        open={openBuyTickets}
        onClose={handleCloseBuyTickets}
        ticketData={ticketData}
      />
      <SettingsBottomSheetDialog
        open={openSettings}
        onClose={handleCloseSettings}
      />
    </Flex>
  );
};

export default OverviewView;
