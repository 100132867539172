import { createAsyncAction } from 'typesafe-actions';
import {
  TermsAndConditionsConsentStatusResponse,
  TermsAndConditionsConsentStatusUpdatedResponse,
  Customer,
} from '../types';

export const fetchTermsAndConditionsConsentStatus = createAsyncAction(
  'TERMS_CONSENT_STATUS_REQUEST',
  'TERMS_CONSENT_STATUS_SUCCESS',
  'TERMS_CONSENT_STATUS_FAILURE',
)<void, TermsAndConditionsConsentStatusResponse, Error>();

export const acceptLatestTermsAndConditions = createAsyncAction(
  'UPDATE_TERMS_CONSENT_STATUS_REQUEST',
  'UPDATE_TERMS_CONSENT_STATUS_SUCCESS',
  'UPDATE_TERMS_CONSENT_STATUS_FAILURE',
)<void, TermsAndConditionsConsentStatusUpdatedResponse, Error>();

export const getCustomerProfile = createAsyncAction(
  'GET_CUSTOMER_PROFILE_REQUEST',
  'GET_CUSTOMER_PROFILE_SUCCESS',
  'GET_CUSTOMER_PROFILE_FAILURE',
)<undefined, Customer, Error>();
