import React from 'react';
import styled from 'styled-components';
import { Route, BrowserRouter } from 'react-router-dom';
import { Spinner, ThemeProvider } from 'pqbc-vas-design-system';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { isDev } from 'pqbc-vas-core';
import { setNativeLanguage } from './i18n';
import { App, GlobalStyle } from './components';
import configureStore from '../core/redux/store';

const { store, persistor } = configureStore();

if (isDev()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { injectNativeBridgeMock } = require('pqbc-vas-native-bridge');
  injectNativeBridgeMock({ language: 'en' });
}

setNativeLanguage();

const LoadingContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
`;

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        }
        persistor={persistor}
      >
        <BrowserRouter>
          <ThemeProvider>
            <GlobalStyle />
            <Route path='/' component={App} />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default Root;
