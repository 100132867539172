import { createAsyncAction, createAction } from 'typesafe-actions';
import { Product } from '../../types';
import { Ticket, Bundle } from '../types';

export const getMyTickets = createAsyncAction(
  'GET_MY_TICKETS_REQUEST',
  'GET_MY_TICKETS_SUCCESS',
  'GET_MY_TICKETS_FAILURE',
)<void, { activated: Ticket[]; bundles: Bundle[] }, Error>();

export const getAvailableProducts = createAsyncAction(
  'GET_AVAILABLE_PRODUCTS_REQUEST',
  'GET_AVAILABLE_PRODUCTS_SUCCESS',
  'GET_AVAILABLE_PRODUCTS_FAILURE',
)<void, Product[], Error>();

export const saveNewTicket = createAction('SAVE_NEW_TICKET')<{
  product?: Product;
  amountOfTickets?: number;
}>();

export const clearNewTicket = createAction('CLEAR_NEW_TICKET')();

export const activateTicket = createAction('ACTIVATE_TICKET')<{
  bundle?: Bundle;
  amountOfTickets?: number;
}>();

export const startTicketActivation = createAsyncAction(
  'START_TICKET_ACTIVATION_REQUEST',
  'START_TICKET_ACTIVATION_SUCCESS',
  'START_TICKET_ACTIVATION_FAILURE',
)<void, void, void>();

export const startPayconiqPayment = createAsyncAction(
  'START_PAYCONIQ_PAYMENT_REQUEST',
  'START_PAYCONIQ_PAYMENT_SUCCESS',
  'START_PAYCONIQ_PAYMENT_FAILURE',
)<void, void, void>();

export const buyTicket = createAsyncAction(
  'BUY_TICKET_REQUEST',
  'BUY_TICKET_SUCCESS',
  'BUY_TICKET_FAILURE',
)<void, void, void>();
