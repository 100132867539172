import styled from 'styled-components';
import { Spinner, Header4 } from 'pqbc-vas-design-system';

export const LoadMoreSpinner = styled(Spinner)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Text = styled(Header4)`
  color: ${p => p.theme.colors.secondary500};
  margin-top: 8px;
  margin-bottom: 8px;
`;
